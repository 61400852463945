import $ from "jquery";

(async () => {
  $(".js-search-form").each(function () {
    const $siteHeader = $(this).closest(".site-header");
    const $search = $(this).closest(".search");
    const headerTypes = ["site-header--type-1"];
    const hideMenu = headerTypes.some((type) => $siteHeader.hasClass(type));

    $(this).on("click", function () {
      if (hideMenu) {
        $siteHeader
          .find(".site-header__mega-menu")
          .addClass("hide-on-search-focus");
      }
      $search.addClass("active");
    });

    const $searchInput = $search.find(".js-search-input");
    const $searchClose = $search.find(".js-search-close");

    $searchClose.on("click", function () {
      if (hideMenu) {
        $siteHeader
          .find(".site-header__mega-menu")
          .removeClass("hide-on-search-focus");
      }
      $search.removeClass("active");
      $searchInput.val("").focus();
    });
  });
})();
